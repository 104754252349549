/* General Styles
================== */
/*@import url(https://fonts.googleapis.com/css?family=Oswald:400,300);*/
@font-face {
  font-family: 'Futura';
  font-weight: 300;
  src: url('/sites/all/themes/vent_axia_nl/fonts/FuturaT-Light_2.otf') format('opentype');
}
@font-face {
  font-family: 'Futura';
  font-weight: 400;
  src: url('/sites/all/themes/vent_axia_nl/fonts/FuturaT-Medium_0.otf') format('opentype');
}
p, a, li, span, div {
  font-family: 'Futura', sans-serif;
  font-weight: 300;
  font-size: 16px;
}
h1, h2, h3, h4, h5, h6, strong {
  font-family: 'Futura', sans-serif;
  font-weight: 400;
}
p {
  margin: 0 0 15px;
  line-height: 1.5;
}
a, input, img {
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}
img {
  max-width: 100%;
  height: auto;
}
.panel-pane {
  width: 100%;
  float: left;
}
h1.page-title {
  text-align: center;
  font-weight: 300;
  text-transform: uppercase;
  margin: 0 0 30px 0;
  padding-top: 30px;
}
.page-header h1 {
  margin-top: 10px;
}
#main-wrapper {
  padding-top: 50px;
}
#simplemenu {
  z-index: 1;
}
#edit-field-range-ventilation-value-wrapper,
#edit-field-motor-value-wrapper,
#edit-field-range-product-type-value-wrapper,
li.translation_de {
  display: none;
}

/* Header Styles
================= */
header {
  position: fixed;
  width: 100%;
  z-index: 998;
}
.navbar-default {
  background-color: #1d4190;
  border: none;
  border-radius: 0;
}
.navbar-default .navbar-nav > li > a, .navbar-default .navbar-nav > li > nolink {
  color: #fff;
}
li.menu-link-producten {
  padding-top: 14px;
}
.navbar-default .navbar-nav > li > a:hover,
.navbar-default .navbar-nav > li > a:focus,
.navbar-default .navbar-nav > .active > a:focus,
.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .active > a:focus {
  color: #555;
  background-color:#e7e7e7;
}
.navbar-form {
  float: left !important;
}
.navbar-form .form-control {
  border-radius: 0;
  box-shadow: none;
  border: none;
  min-width: 200px;
}
.page-header {
  margin: 0;
  padding-bottom: 0;
}
.tabs,
.nav-tabs {
  margin: 18px 0;
}
.dropdown-menu {background-color: #1d428d;}
.dropdown-menu > li > a {color: #fff; background: #1d428d; font-weight: 100;}
.dropdown-menu > li > a:hover {background-color: #e7e7e7;}
.dropdown-menu > .active > a {color: #555; background-color: #e7e7e7;}

/* Homepage Styles
=================== */
.carousel .item {
  width: 100%;
  float: left;
  background-repeat: no-repeat;
  background-size: cover;
}
.slider-content {
  background-color: rgba(255,255,255,0.8);
  width: 35%;
  margin: 110px auto;
  text-align: center;
  padding: 40px;
}
.slider-content h2 {
  margin: 0;
  text-transform: uppercase;
  font-weight: 300;
  color: #000;
}
.slider-content p {
  margin: 15px 0;
  font-size: 1.2em;
  color: #000;
}
.slider-content a {
  display: inline-block;
  background: #1d4190;
  color: #fff;
  padding: 10px 20px;
  font-size: 0.9em;
}
.slider-content a:hover {
  text-decoration: none;
  background: #3d69c8;
}
.carousel-indicators .active {
  background-color: #1d4190;
  width: 14px;
  height: 14px;
}
.carousel-indicators li {
  border: 2px solid #1d4190;
  width: 13px;
  height: 13px;
}
.homepage-categories {
  background: #dadada;
  padding-top: 30px;
  padding-bottom: 30px;
}
.categories-intro {
  text-align: center;
  color: #000;
}
.categories-intro h1 {
  font-weight: 300;
  text-transform: uppercase;
  font-size: 2.1em;
  margin: 0;
}
.categories-intro p {
  font-size: 1.2em;
  margin: 15px 0 50px;
}
.category-listing {
  text-align: center;
}
.category-listing a {
  display: block;
  width: 100%;
  margin-top: 15px;
  text-transform: uppercase;
  color: #000;
  font-size: 1.2em;
}
.pane-link-icon-scroller-panel-pane-1 h2.pane-title {
  font-weight: 300;
  border-top: 1px solid #000;
  padding-top: 15px;
  margin: 20px 0;
  font-size: 2em;
}
.horizontal-links ul {
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
}
.horizontal-links ul li {
  display: inline-block;
  text-align: center;
  padding: 40px 0;
  width: 378px;
  border-right: 1px solid #171717;
}
.horizontal-links ul li:last-child {
  border-right: none;
}
.horizontal-links ul li h2 {
  text-transform: uppercase;
  font-weight: 300;
  margin: 30px 0 10px 0;
  font-size: 1.6em;
}
.horizontal-links ul li a {
  color: #1d4190;
}
/* Footer Styles
================= */
footer {
  background: #d9d9d9;
  padding: 30px 0;
  margin-bottom: 0 !important;
}
.footer-top {
  width: 100%;
  float: left;
  border-bottom: 1px solid #282828;
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.footer-top img {
  max-width: 150px;
}
.footer-top small {
  vertical-align: bottom;
  line-height: 0.9;
  font-weight: 400;
}
.footer-top ul {
  list-style-type: none;
  margin: 0;
  padding: 7px 0 0 0;
  float: right;
}
.footer-top ul li {
  display: inline-block;
}
.footer-top ul li a {
  font-size: 85%;
  color: #282828;
  font-weight: 400;
  display: block;
  border-right: 1px solid;
  padding: 0 8px;
}
.footer-top ul li:last-child a {
  border-right: none;
  padding-right: 0;
}
.footer-navigations p {
  margin: 10px 0 3px;
  display: inline-block;
  font-weight: 400;
}
ul.footer-nav,
ul.footer-social {
  list-style-type: none;
  width: 100%;
  float: left;
  margin: 0;
  padding: 0;
}
ul.footer-nav li,
ul.footer-social li {
  margin-bottom: 5px;
}
ul.footer-nav a {
  color: #282828;
}
ul.footer-social a {
  color: #979797;
}
ul.footer-social a i {
  margin-right: 5px;
}
ul.footer-social a:hover {
  text-decoration: none;
  color: #282828;
}
.footer-contact p {
  font-weight: 400;
  margin-bottom: 10px;
}
.footer-contact label {
  width: 100%;
  float: left;
  font-weight: 300;
  margin: 0;
}
.footer-contact input[type="text"],
.footer-contact input[type="email"] {
  width: 100%;
  float: left;
  padding: 5px;
  margin-bottom: 10px;
  border-radius: 0;
}
.footer-contact textarea {
  width: 100%;
  float: left;
  padding: 5px;
  margin-bottom: 10px;
  min-height: 120px;
  border-radius: 0;
}
.footer-contact .grippie {
  display: none;
}
.footer-contact input[type="submit"] {
  color: #fff;
  background: #1d4190;
  border: none;
  padding: 8px 20px;
  border-radius: 0;
  font-weight: 300;
}
.footer-contact input[type="submit"]:focus,
.footer-contact input[type="submit"]:hover {
  background: #3d69c8;
}
/* Downloads Page
================== */
#quicktabs-downloads_tabs_nl .item-list {
  text-align: center;
}
ul.quicktabs-tabs {
  margin-bottom: 30px;
}
ul.quicktabs-tabs li {
  display: inline-block;
  padding: 8px 15px;
  margin: 0;
  border-right: 1px solid #282828;
}
ul.quicktabs-tabs li:last-child {
  border-right: none;
  padding-right: 0;
}
.brochure-holder {
  width: 25%;
  float: left;
  padding: 20px;
  text-align: center;
  min-height: 375px;
}
.brochure-holder a img {
  /*-webkit-box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.75);
  box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.75);
  opacity: 1;*/
  border: 1px solid #ddd;
}
.brochure-holder a img:hover {
  opacity: 0.7;
}
.brochure-holder p {
  margin: 10px 0;
}
.brochure-holder a.brochure-button {
  display: inline-block;
  background: #1d4190;
  color: #fff;
  padding: 5px 10px;
  margin: 0 5px;
}
.brochure-holder a.brochure-button i {
  font-size: 1.4em;
  margin-right: 5px;
}
.brochure-holder a.brochure-button:hover,
.brochure-holder a.brochure-button:focus {
  background: #3d69c8;
  text-decoration: none;
}
.iframe-holder {
  margin-top: 20px;
}
.iframe-wrapper {
  height: 1000px;
}
.iframe-wrapper iframe {
  height: 100%;
  width: 100%;
}

@media (max-width: 990px) {
  .block-aw-downloads .btn.btn-primary.btn-sm{
    margin-top:10px;
    margin-bottom:10px;
  }
}

/* ####  PRODUCT RANGE CATEGORY PAGE   #### */

.abroad-range-buy .btn {
  background: #1d428d;
  color: #fff;
  font-size: 0.9em;
  padding: 0.4em 1.7em;
  border: 0;
  border-radius: 0;
  margin-right: 20px;
}
.abroad-range-buy .btn:hover {
  background: #5b5b5b;
  color: #fff;
  text-decoration: none;
}
.abroad-range-buy .btn-link {
  background: transparent;
  color: #000;
}
.abroad-range-buy .btn-link:hover {
  text-decoration: none;
  cursor: auto;
  color: #000;
  background: transparent;
}
.view-product-range .views-row {
  width: 33%;
  height: 373px;
  text-align:center;
  float:left;
  margin-bottom: 1.5em;
  padding-bottom: 3em;
  border-bottom: 1px solid #aaa;
  -webkit-box-shadow: 11px -10px 0px -10px rgba(170,170,170,1);
  -moz-box-shadow: 11px -10px 0px -10px rgba(170,170,170,1);
  box-shadow: 11px -10px 0px -10px rgba(170,170,170,1);
  padding-left:5px;
}
.view-product-range .pagination.pager {
  display: block;
}
.view-product-range .views-row div {
  padding-left: 10px;
  padding-right: 10px;
}
.view-product-range .views-row .views-field-title .field-content a {
  color: #333;
  font-size: 1.2em;

}

.view-product-range .views-row .views-field-rendered-entity {
  padding: 10px 0;
}
.page-range .view-product-category-taxonomy-header .views-field-description {
  text-align: center;
  margin-top: -30px;
}
.page-range .view-product-category-taxonomy-header .views-field.views-field-field-category-heroimage {
  text-align: center;
}
.page-range .view-product-category-taxonomy-header .views-field.views-field-name-1 {
  margin-top: 30px;
}
.page-range .view-product-range .views-row:nth-last-of-type(1){
  -webkit-box-shadow: 0px 0px 1px -10px rgba(170,170,170,1);
  -moz-box-shadow: 0px 0px 1px -10px rgba(170,170,170,1);
  box-shadow: 0px 0px 1px -10px rgba(170,170,170,1);
  display: block;
}
.page-range .view-product-range .view-content {
  display: inline-block;
  width: 100%;
}
.view-product-range .views-field-field-range-images {
  padding-bottom: 20px;
}
.view-product-range .views-exposed-form {
  padding-bottom: 30px;
  float:right;
}
.page-range .contextual-links-region {
  border-top: 1px solid #777;
}
.page-range .contextual-links-region h2 {
  font-weight:300;
}
ul.quicktabs-tabs.quicktabs-style-sky li.active a, ul.quicktabs-tabs.quicktabs-style-sky li.active a:visited {
  height: 40px;
  min-height: 40px;
  line-height: 35px;
  font-weight: normal;
  border: 1px solid #777;
  background: 0;
  color: #777;
  font-size: .95em;
  font-weight: normal;
  top: -0.1em;
  border-bottom: 0;
  background-color: #fff;
  z-index: 1;
}
ul.quicktabs-tabs.quicktabs-style-sky li a, ul.quicktabs-tabs.quicktabs-style-sky li a:visited {
  float: left;
  display: block;
  height: 40px;
  min-height: 40px;
  line-height: 40px;
  padding: 0 8px;
  text-decoration: none;
  border-right: 0;
  border-top: 0;
  font-size: .95em;
  background: #fff url('images/bg-shade-light.png') repeat-x bottom left;
  color: #777;
  position: relative;
  padding-left: 20px;
  padding-right: 20px;
}
ul.quicktabs-tabs.quicktabs-style-sky li {
  float: left;
  font-weight: normal;
  list-style: none;
  margin: 1.01px 0 0 0;
  height: 40px;
  min-height: 40px;
  padding: 0;
}
ul.quicktabs-tabs.quicktabs-style-sky li.active {
  margin: 1.01px 0 0 0;
}
ul.quicktabs-tabs.quicktabs-style-sky li a:hover, ul.quicktabs-tabs.quicktabs-style-sky li.active a:hover {
  text-decoration: none;
  background: 0;
  color: #777;
  z-index:1;
  top: -0.1em;
  background-color: #fff;
  border: 1px solid #777;
  border-bottom:1px solid #ccc;
  height: 40px;
  min-height: 40px;
  line-height: 35px;
}
ul.quicktabs-tabs.quicktabs-style-sky li.active a:hover {
  text-decoration: none;
  background: 0;
  color: #777;
  z-index:1;
  top: -0.1em;
  background-color: #fff;
  border: 1px solid #777;
  height: 40px;
  min-height: 40px;
  line-height: 35px;
  border-bottom:0;
}
ul.quicktabs-tabs li {
  display: inline-block;
  padding: 8px 15px;
  margin: 0;
  border-right: 0;
}
.quicktabs_main.quicktabs-style-sky {
  border:0px;
  border-top: 1px solid #777;
  clear: both;
  padding: 10px 5px 0 5px;
  position: relative;
  top: -0.1em;
  background: #fff;

}
ul.quicktabs-tabs.quicktabs-style-sky li:first-child a {
  border-left: solid 1px #fff;
}
ul.quicktabs-tabs.quicktabs-style-sky li.active:first-child a {
  border-left: solid 1px #777;
}
ul.quicktabs-tabs.quicktabs-style-sky li:first-child a:hover {
  border-left: solid 1px #777;
}

/* HIDE UK Technical Tab on Products Range */
#quicktabs-tab-product_range_tabs-1  {
  display:none;
}

.product-buttons .btn-primary {
  background: #1d428d;
  color: #fff;
  font-size: 0.9em;
  padding: 0.4em 1.7em;
  border: 0;
  border-radius: 0;
}

.product-buttons .btn-primary:hover {
  background: #5b5b5b;
  color: #fff;
  text-decoration: none;
}


.view-featured-products .item img.large{
  float:right;
  margin-right: 100px;
}
.pane-featured-products-panel-pane-1 h2.pane-title{
  font-weight:300;
  text-align:center;
  text-transform: uppercase;
  font-family: 'Futura', sans-serif;
  font-size: 2.1em;
}
.node-type-product-range .page-title{
  float:left;
}
.pane-product-range-panel-pane-1 .pane-title{
  text-align: center;
  font-weight:300;
  text-transform: uppercase;
  font-family: 'Futura', sans-serif;

}
.node-type-product-range .horizontal-links.view-product-range .views-row{
  width: initial;
}

/* ####  RELATED PRODUCTS SLIDER   #### */

.pane-related-products-scroller-panel-pane-1 {
  border-top: 1px solid #5c5c5c;
}

.rps-title  {
  margin: 1em 0 1em 0;
}

ul li .rps-title a {
  color: #595959;
  font-size: 1.2em;
}

ul li .rps-more a, .view-product-range .rps-more a {
  background: #1d428d;
  color: #fff;
  font-size: 0.9em;
  padding: 0.4em 1.7em;
  display: inline-block;
  margin-top: 15px;
}

ul li .rps-more a:hover {
  background: #5b5b5b;
  color: #fff;
  text-decoration: none;
}

/* Models / Accessories Tab Displays
===================================== */
.clearboth {
  clear:both;
  overflow: hidden;
}
.model-row {
  margin-bottom: 0.5em;
  min-height: 100px;
}
.model-image {
  float:left;
  width: 120px;
  text-align: center;
}
.model-details {
  padding: 0.5em 0 1em 0;
  margin-left: 120px;
  border-bottom: 1px solid #e4e4e4;
}
.model-title{
  font-size: 1.2em;
}
.model-desc {
  padding: 0.5em 0;
}
.model-sku {
  font-size: 0.9em;
  font-weight: bold;
}
.node-type-product-range .pane-quicktabs-product-range-tabs {
  margin-top: -80px;
}
.node-type-product-range .pane-product-slideshow-panel-pane-1 {
  width: 110px;
  display: inline-block;
  float:left;
}
.node-type-product-range .radix-layouts-rightcolumn1 {
  /*width: 50%;
  float: right;*/
}
.node-type-product-range .pane-product-slideshow-panel-pane-2 {
  width: 57%;
  margin-left: 50px;
}
.node-type-product-range .pane-product-slideshow-panel-pane-2 {
  width: 57%;
  margin-left: 50px;
}
.node-type-product-range .radix-layouts-leftcolumn1 {
  /*width: 50%;*/
}
.node-type-product-range .pane-product-slideshow-panel-pane-2.single {
  width: 100%;
  margin-left: 50px;
}
.node-type-product-range .pane-product-slideshow-panel-pane-1{
  display: none;
}
.page-range .view-header {
  float: left;
  top: 50px;
  position: relative;
  font-size: 26px;
}
.view-product-range-tabs.view-display-id-block_5 .item-list ul li {
  list-style : none;
  margin-left: 0px;
}
.view-product-category-taxonomy-header .views-field-description {
  display:none;
}

/* Webform Modal Styles
======================== */
.modal-open .modal {
  background: rgba(0,0,0,0.7);
}
.modal.in .modal-dialog {
  margin-top: 95px;
}
.number-nolink {
  color: #000;
}
.number-nolink:hover {
  text-decoration: none;
  cursor: auto;
  color: #000;
}
.page-node-done .links {
  display: none;
}

.carousel-caption a.carousel-read-more {
  color: #fff;
  background: #1d4190;
  border: none;
  padding: 8px 20px;
  border-radius: 0;
  font-weight: 300;
}
.carousel-caption a.carousel-read-more:focus,
.carousel-caption a.carousel-read-more:hover {
  background: #3d69c8;
  text-decoration: none;
}

.dwn-deselectall.btn-primary,
.dwn-selected.btn-primary {
  color: #fff;
  background: #1d4190;
  border: none;
  padding: 8px 14px;
  border-radius: 0;
  font-weight: 300;
  opacity: 1;
  font-size: 0.8em;
}
.dwn-deselectall.btn-primary:hover,
.dwn-selected.btn-primary:hover {
  background: #3d69c8;
  text-decoration: none;
}

#quicktabs-download_tabs_nl .item-list {
  text-align: center;
}

#quicktabs-download_tabs_nl ul.quicktabs-tabs li {
  border-right: 1px solid #ddd;
  padding: 8px 18px 8px 12px;
}

#quicktabs-download_tabs_nl ul.quicktabs-tabs li.last {
  border-right: 0;
}

.download-content {
  box-shadow: none;
  border-bottom: 0px;
}

.download-content {
  border:1px solid darkgray;
}

ul.links.inline li.translation_en {display: none;}

@media (max-width: 995px) {  /* Create collapse menu breakpoint to happen before default 768px */
  .navbar-header {
    float: none;
  }
  .navbar-toggle {
    display: block;
  }
  .navbar-collapse {
    border-top: 1px solid transparent;
    box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
  }
  .navbar-collapse.collapse {
    display: none!important;
  }
  .navbar-nav {
    float: none!important;
    margin: 7.5px -15px;
  }
  .navbar-nav>li {
    float: none;
  }
  .navbar-nav>li>a {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .navbar-nav>li>nolink {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .navbar-text {
    float: none;
    margin: 15px 0;
  }
  /* since 3.1.0 */
  .navbar-collapse.collapse.in {
    display: block!important;
  }
  .collapsing {
    overflow: hidden!important;
  }
}

@media (max-width: 990px) {
  .footer-top {
    padding-bottom: 15px;
  }
  .footer-top ul {
    width: 100%;
    float: left;
    padding-top: 15px;
  }
  .footer-top ul li:first-child a {
    padding-left: 0;
  }
  .slider-content {
    width: 60%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .container {
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }

  .navbar > .container .navbar-brand, .navbar > .container-fluid .navbar-brand {
    margin-left: 0;
  }

  footer {
    padding: 30px 15px;
  }

  .btn-info {
    color: #fff;
    background-color: #5bc0de;
    border-color: #46b8da;
  }

}

@media only screen and (min-width: 769px) {
  .page-range .view-product-range .views-row{
    width:25%;

  }
  .page-range .view-product-range .views-row:nth-of-type(4n){
    -webkit-box-shadow: 0px 0px 1px -10px rgba(170,170,170,1);
    -moz-box-shadow: 0px 0px 1px -10px rgba(170,170,170,1);
    box-shadow: 0px 0px 1px -10px rgba(170,170,170,1);
  }
}

@media screen and (min-width: 768px)
{
  .pane-featured-products-panel-pane-1 .carousel-caption {
    left: 20%;
    right: 44%;
    padding-bottom: 160px;
    color:#000;
  }
  .pane-featured-products-panel-pane-1 .carousel-caption h3 a{
    text-transform: uppercase;
    font-family: 'Futura', sans-serif;
    font-size: 24px;
    color:#000;
  }
  .pane-featured-products-panel-pane-1 .carousel-caption{
    color:#000;
    text-shadow:none;
    font-size: 1.2em;
  }
}

@media screen and (max-width: 768px) {
  .product-buttons .btn-primary {
    margin-bottom: 10px;
  }

  div#ui-accordion-quickset-product_range_tabs_accordian-panel-0,
  div#ui-accordion-quickset-product_range_tabs_accordian-panel-1,
  div#ui-accordion-quickset-product_range_tabs_accordian-panel-2,
  div#ui-accordion-quickset-product_range_tabs_accordian-panel-3,
  div#ui-accordion-quickset-product_range_tabs_accordian-panel-4 {
    height: auto !important;
  }

  #block-views-link-icon-scroller-block-1 h2,
  .pane-related-products-scroller-panel-pane-1 h2 {
    font-size: 20px;
  }
  .navbar-default .navbar-nav > li > a, .navbar-default .navbar-nav > li > nolink {
    color: #fff;
  }
  li.menu-link-producten {
    padding: 10px 15px;
  }

  .btn-info {
    background: #1d428d;
    color: #fff;
    font-size: 0.9em;
    padding: 0.4em 1.7em;
    border: 0;
    border-radius: 0;
    margin-bottom: 10px;;
  }

}

@media only screen and (max-width: 768px) and (min-width: 426px) {
  .page-range .view-product-range .views-row:nth-of-type(3n){
    -webkit-box-shadow: 0px 0px 1px -10px rgba(170,170,170,1);
    -moz-box-shadow: 0px 0px 1px -10px rgba(170,170,170,1);
    box-shadow: 0px 0px 1px -10px rgba(170,170,170,1);
  }
  .page-range .view-product-range .views-row{
    width:33%;
  }

  .view-featured-products .item img.large {
    margin-right: 20px;
  }


}

@media only screen and (max-width: 736px) and (orientation: landscape)
{
  .view-product-range .views-exposed-form {
    display: none;
  }

  .page-range .view-header {
    float: none;
    top: 0px;
    margin-bottom: 20px;
    text-align: center;
  }

  .brochure-holder {
    width: 33%;
  }

  .brochure-holder a.brochure-button {
    margin: 0 0 0 -60px;
    bottom: 10px;
    position: absolute;
    width: 120px;
  }

  .brochure-holder {
    position: relative;
    margin-bottom: 10px;
  }

}

@media (max-width: 640px) {
  iframe {
    /*position: absolute;
    top: 0;
    left: 0;*/
    width: 100%;
    height: 100%;
  }

}

@media (max-width: 510px) {
  .slider-content {
    width: 90%;
  }
}

@media only screen and (max-width: 425px) {
  .page-range .view-product-range .views-row{
    width:50%;
  }
  .node-type-product-range .pane-quicktabs-product-range-tabs{
    display:none;
  }
  .page-range .view-product-range .views-row:nth-of-type(2n){
    -webkit-box-shadow: 0px 0px 1px -10px rgba(170,170,170,1);
    -moz-box-shadow: 0px 0px 1px -10px rgba(170,170,170,1);
    box-shadow: 0px 0px 1px -10px rgba(170,170,170,1);
  }
}

@media only screen and (max-width: 414px)
{
  .node-type-product-range .pane-product-slideshow-panel-pane-2 {
    margin-left: 0 !important;
  }
  .view-featured-products .item img.large {
    float:none;
    margin-right: 0;
    margin: 0 auto;
    width: 50%;
    height: 50%;
  }

  .pane-featured-products-panel-pane-1 .carousel-caption h3 a {
    text-transform: uppercase;
    font-family: 'Futura', sans-serif;
    font-size: 0.8em;
    color: #000;
  }

  .pane-featured-products-panel-pane-1 .carousel-caption {
    color: #000;
    text-shadow: none;
    width: 90%;
    left: 5%;
    position: relative;
    margin-bottom: -100px;  /* god knows why!! */
  }

  .pane-featured-products-panel-pane-1 .carousel-caption p {
    font-size: 0.8em;
    line-height: 1.2em;
  }

  .categories-intro h1 {
    font-size: 1.4em;
  }

  .category-listing a {
    font-size: 1em;
  }

  .pane-featured-products-panel-pane-1 h2.pane-title {
    font-size: 1.8em;
  }

  .horizontal-links ul li {
    padding: 40px 0;
    width: 378px;
    border-right: none;
  }

  .horizontal-links ul li h2 {
    margin: 20px 0 10px 0;
    font-size: 1.2em;
  }

  #views-bootstrap-carousel-1 .carousel-inner {
    min-height: 480px;  /* Trying to keep scroller height at steady px so that it doesn't expand/retract on each product */
  }

  .view-product-range .views-row .views-field-title .field-content a {
    font-size: 1em;
  }

  .view-product-range .views-row div {
    line-height: 1.2em;
  }

  .view-product-range .views-row {
    height: 290px;
  }

  .page-range .view-header {
    float: none;
    top: 0px;
    margin-bottom: 20px;
    text-align: center;
  }

  .view-product-range .views-exposed-form {
    display: none;
  }

  .footer-top {
    float: left;
    border-bottom: 1px solid #282828;
    padding-bottom: 20px;
    margin-bottom: 20px;
  }

  .footer-top ul {
    float: right;
  }

  .footer-top .pull-left small {
    display: block;
    margin-top: 20px;
  }

  .footer-top ul li {
    display: block;
  }

  .footer-top ul li a {
    border-right: 0;
    padding: 0;
  }

  #quicktabs-downloads_tabs ul.quicktabs-tabs li {
    padding: 5px 3px;
  }

  #quicktabs-downloads_tabs ul.quicktabs-tabs li a {
    font-size: 0.9em;
  }

  .brochure-holder {
    width: 50%;
  }

  .brochure-holder a.brochure-button {
    margin: 0 0 0 -60px;
    bottom: 10px;
    position: absolute;
    width: 120px;
  }

  .brochure-holder {
    position: relative;
    margin-bottom: 10px;
  }

  #quicktabs-download_tabs_nl ul.quicktabs-tabs li {
    border-right: 1px solid #ddd;
    padding: 8px 7px 8px 2px;
  }

}

@media only screen and (max-width: 375px)
{
  .horizontal-links ul li {
    padding: 40px 0;
    width: 328px;
    border-right: none;
  }

}

@media only screen and (max-width: 320px)
{
  .horizontal-links ul li {
    padding: 40px 0;
    width: 273px;
    border-right: none;
  }

  #quicktabs-downloads_tabs ul.quicktabs-tabs li a {
    font-size: 0.8em;
  }

}

.navbar-nav > li > .dropdown-menu > li.first {
  margin-top:9px;
}

.navbar-nav > li > .dropdown-menu > li {
  background-color:#1d428d;
}

.navbar-nav > li > .dropdown-menu{
  background:none;
  border:none;
  box-shadow:none;
}

@media screen and (min-width:768px){
  .navbar-nav > li > .dropdown-menu > li {
    box-shadow:0 6px 12px rgba(0, 0, 0, 0.175);
  }
}

@media screen and (max-width:778px){
  .navbar-nav > li > .dropdown-menu > li.first {
    margin-top:0px;
  }

  .dropdown-menu > .active > a{
    color:#333 !important;
  }

  .navbar-default .navbar-nav .open .dropdown-menu > li > a{
    color:white;
  }

  .navbar-default .navbar-nav .open .dropdown-menu > li > a:hover, .navbar-default .navbar-nav .open .dropdown-menu > li > a:focus{
    color:lightgrey;
  }
}

.pane-quicktabs-product-range-tabs-accordian .ui-accordion-content {
  border:1px solid grey;
}



@media screen and (max-width:778px) {
  .carousel-inner > .item > img, .carousel-inner > .item > a > img{
    margin: auto !important;
    display: block;
    width: 200px;
    float: none !important;
  }

  .view-featured-products .item > a{
    display: block;
    position: relative;
    width: 100%;
  }

  .carousel-caption{
    position: initial;
    color: #000;
    text-shadow: none;
  }

  .carousel-indicators{
    bottom:-11px;
  }
}

.node-type-product-range .pane-quicktabs-product-range-tabs-accordian{
  display:none;
}

@media screen and (max-width:768px){
  .node-type-product-range .pane-quicktabs-product-range-tabs-accordian{
    display:block;
  }
  .node-type-product-range .pane-quicktabs-product-range-tabs{
    display:none;
  }

  /* quick fix for gap above accordian on product page*/
  #block-system-main > div > .contextual-links-region > .panel-display.va-type-1.clearfix > .container-fluid > .row{
    margin-top:0 !important;
    margin-bottom:0 !important;
  }
}

h3#ui-accordion-qui-product_range_tabs_accordian-header-5 {
  display: none;
}
.horizontal-links {
  width: 100%;
  height: 390px;
  overflow:hidden;
}
.horizontal-links ul {
  margin: 0;
  padding: 0;
  list-style: none;
  height: 370px;
  -webkit-padding-start: 0px;
  -webkit-margin-before: 0px;
  -webkit-margin-after: 0px;
}
.horizontal-links ul li{
  -webkit-padding-start: 0px;
  -webkit-margin-before: 0px;
  -webkit-margin-after: 0px;
}
.hl {
  position: absolute;
  pointer:hand;
  cursor: pointer;
}
.hl-left.hl {
  margin-top: 100px;
  margin-left: -30px;
}
.hl-right.hl {
  display: inline;
  margin-top: -290px;
}
@media only screen and (max-device-width: 1024px) {
  .hl-left.hl {
    margin-top: 100px;
    margin-left: 0px;
  }
  .hl-right.hl {
    display: inline;
    margin-top: -290px;
    left: 0px;
  }
}

@media only screen and (max-device-width: 480px) {
  .hl-left.hl {
    margin-top: 100px;
    margin-left: 0px;
  }
  .hl-right.hl {
    display: inline;
    margin-top: -290px;
    padding-left:-30px;
    left: auto;
  }
}
#edit-basic--2 {
  position: absolute;
}
.autolist li {
  display: block;
  background-color: #fff;
  padding: 10px;
  border-bottom: #ccc solid 1px;
  cursor: pointer;
}
.autolist {
  top: 3px;
  position: relative;
}
.view-news-page .views-field-title > span > a {
  font-size: 2.1em;
}
div#breadcrumb {
  top: 60px;
  position: relative;
}
#views-exposed-form-news-page-panel-pane-1 #edit-field-news-category-tid-wrapper {
  width: 100%;
}
.view-featured-products img.footer-images {
  float: right;
}
.page-range #edit-field-range-temperature-value-wrapper,
.page-range #edit-field-range-temperature-value-wrapper,
.page-range #edit-field-internal-external-value-wrapper,
.page-range #edit-field-range-install-type-value-wrapper,
.page-range #edit-field-range-product-type-value-wrapper,
.page-range #edit-field-impeller-value-wrapper,
.page-range #edit-field-range-room-type-value-wrapper,
.page-range #edit-field-range-mode-value-wrapper
{
  display:none;
}
div#breadcrumb {
  top: 50px;
  position: relative;
}
div#block-menu-menu-nl-admin-menu .nav {
  display: inline-flex;
  background-color: #f0ae21;
  width: 100%;
}
div#block-menu-menu-nl-admin-menu{
  background-color: #f0ae21;
}
#block-menu-menu-nl-admin-menu h2 {
  display: inline-flex;
  border: 13px #f0ae21 solid;
  padding: 0px;
  bottom: -27px;
  position: relative;
  font-size: 16px;
  background-color: #f0ae21;
}
#block-menu-menu-nl-admin-menu .nav:before {
  content: " NL Admin:";
  display: inline-flex;
  position: relative;
  padding: 10px;
  font-weight: 500;
}
div#block-menu-menu-nl-admin-menu .nolink {
  position: relative;
  top: 10px;
}
.node-type-column-page .field-name-field-section-columns-link a{
  display: inline-block;
  background: #1d4190;
  color: #fff;
  padding: 10px 20px;
  font-size: 0.9em;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
  text-decoration:none;
  width: 100%;
  height: 60px;
  font-weight: 500;
}
.node-type-column-page .field-name-field-section-columns-link a:hover {
  text-decoration: none;
  background: #3d69c8;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
  text-decoration:none;
}
@media all and (min-width: 480px) {
  .node-type-column-page .columns_4 .field-type-field-collection>.field-items>.field-item{
    width: 25%;
    float: left;
    padding: 10px;
  }

  .node-type-column-page .columns_3 .field-type-field-collection>.field-items>.field-item {
    width: 33.3%;
    float: left;
    padding: 10px;
  }

  .node-type-column-page .columns_2 .field-type-field-collection>.field-items>.field-item {
    width: 50%;
    float: left;
    padding: 10px;
  }

  .node-type-column-page .columns_1 .field-type-field-collection>.field-items>.field-item {
    width: 100%;
    float: left;
    padding: 10px;
  }
}
@media all and (max-width: 1024px) {
  .node-type-column-page .container.breadcrumbs.header, .node-type-column-page #main-wrapper {
    margin: 0 15px;
  }
  #footer{
    overflow: hidden;
  }

}@media all and (max-width: 1024px) and (min-width: 481px) and (orientation: landscape){
  .node-type-column-page .field-name-field-section-columns-link a {
    height: 90px;
  }
}
@media all and (max-width: 1024px) and (min-width: 481px) {
  .node-type-column-page .columns_4 .field-type-field-collection>.field-items>.field-item,
  .node-type-column-page .columns_3 .field-type-field-collection>.field-items>.field-item{
    width: 50%;
    float: left;
    font-size: 5.3vw;
  }
  .node-type-column-page .field-name-field-section-columns-link a {
    display: inline-block;
    background: #1d4190;
    color: #fff;
    padding: 10px 20px;
    font-size: 2.2vw;
    -webkit-transition: all 0.3s ease-in-out 0s;
    -moz-transition: all 0.3s ease-in-out 0s;
    -o-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
    text-decoration: none;
    width: 100%;
    height: 70px;
    font-weight: 400;
  }
}
@media all and (max-width: 480px) {
  .node-type-column-page .columns_4 .field-type-field-collection>.field-items>.field-item,
  .node-type-column-page .columns_3 .field-type-field-collection>.field-items>.field-item,
  .node-type-column-page .columns_2 .field-type-field-collection>.field-items>.field-item{
    width: 100%;
    float: left;
  }
  .node-type-column-page .field-name-field-section-columns-link a {
    display: inline-block;
    background: #1d4190;
    color: #fff;
    padding: 10px 20px;
    font-size: 4.4vw;
    -webkit-transition: all 0.3s ease-in-out 0s;
    -moz-transition: all 0.3s ease-in-out 0s;
    -o-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
    text-decoration: none;
    width: 100%;
    height: 75px;
    font-weight: 500;
  }
}
.field-name-field-section-columns-link.field-type-link-field {
  padding: 10px 0px;
}
.field.field-name-field-section-columns-name a {
  font-size: 20px;
  font-weight: 400;
  color: #555;
  padding: 10px 0px;
}
.field.field-name-field-section-columns-name {

  padding-top:10px;
}
.field.field-name-field-section-columns-image.field-type-image.field-label-hidden {
  height: 160px;
  display: table-cell;
  vertical-align: middle;
  background-color: #fff;
}
.field.field-name-field-section-columns-image.field-type-image.field-label-hidden img {
  max-width: 70%;
  height: auto;
  margin: 0 15%;
}
.field.field-name-field-section-columns-name span {
  font-size: 20px;
  font-weight: 400;
  color: #555;
  padding: 10px 0px;
}

html {
  overflow-x: hidden;
}

@media (max-width: 996px) {
  div#navbar-collapse form.search-form.navbar-form {
    display: none !important;
  }

  li.menu-link-producten {
    padding: 5px !important;
  }

  .navbar-nav > li > a {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
}

.mobile {
  list-style: none;
}

.mobile a {
  color: white;
  font-size: 16px;
}

@media (min-width: 996px) {
  .mobile {
    display: none;
  }
}

@media (max-width: 996px) {
  .mobile {
    display: block;
    float: right;
    margin-right: 15px;
    margin-top: 5px;
    padding: 9px 10px;
  }
}
#sliding-popup.sliding-popup-bottom,
#sliding-popup.sliding-popup-bottom .eu-cookie-withdraw-banner,
.eu-cookie-withdraw-tab{
  background-color: #1d4190;
}
#sliding-popup .popup-content #popup-text h1,
#sliding-popup .popup-content #popup-text h2,
#sliding-popup .popup-content #popup-text h3,
#sliding-popup .popup-content #popup-text p,
#sliding-popup .popup-content #popup-text a
{
  font-weight: 300;
  font-size: 17px;
}

.menu-link-producten- {
  padding: 15px 0 !important;
}
